import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../login/OAuth';
import {
    Button,
    TextField,
    Grid,
    Autocomplete,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    Paper,
    FormHelperText,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Table,
    Box,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Modal,
    Fade,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import utc from 'dayjs/plugin/utc';
import Accordion from '../../components/Accordion';
import InvoiceRetentionTable from '../../components/InvoiceRetentionTable';
import CertificationsTable from '../../components/CertificationsTable';
import UploadDocument from '../../components/UploadDocument';
import { formatDate } from '../../components/Utils';

dayjs.extend(utc);

function EditBuilding() {
    const { id } = useParams();
    const { api } = useAuth();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [editError, setEditError] = useState(null);
    const [itemsError, setItemsError] = useState(null);
    const [workersError, setWorkersError] = useState(null);

    const [workers, setWorkers] = useState([]);
    const [selectedBoss, setSelectedBoss] = useState(null);
    const [selectedWorkers, setSelectedWorkers] = useState([]);
    const [selectedWorkerId, setSelectedWorkerId] = useState('');
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [workerToDissasociate, setWorkerToDissasociate] = useState(null);

    const [client, setClient] = useState([]);
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);

    const [clientInvoices, setClientInvoices] = useState([]);
    const [clientTotalPriceSum, setClientTotalPriceSum] = useState(null);
    const [clientPaidAmountSum, setClientPaidAmountSum] = useState(null);
    const [clientRemainingAmountSum, setClientRemainingAmountSum] = useState(null);
    const [clientTotalRetention, setClientTotalRetention] = useState(null);
    const [clientPaidRetention, setClientPaidRetention] = useState(null);
    const [clientRemainingRetentionSum, setClientRemainingRetentionSum] = useState(null);

    const [providerInvoices, setProviderInvoices] = useState([]);
    const [providerTotalPriceSum, setProviderTotalPriceSum] = useState(null);
    const [providerPaidAmountSum, setProviderPaidAmountSum] = useState(null);
    const [providerRemainingAmountSum, setProviderRemainingAmountSum] = useState(null);
    const [providerTotalRetention, setProviderTotalRetention] = useState(null);
    const [providerPaidRetention, setProviderPaidRetention] = useState(null);
    const [providerRemainingRetentionSum, setProviderRemainingRetentionSum] = useState(null);

    const [items, setItems] = useState([]);

    const [deliveryNotes, setDeliveryNotes] = useState([]);
    const [budget, setBudget] = useState(0);

    const [status, setStatus] = useState('');

    const [buildingForm, setBuildingForm] = useState({
        name: null,
        start_date: null,
        end_date: null,
        budget: null,
        description: null,
        building_status: null,
        client_id: null,
        boss_id: null,
    });

    const [openItemModal, setOpenItemModal] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [deleteId, setDeleteId] = useState(false);
    const [typeDelete, setTypeDelete] = useState(false);

    const [certifications, setCertifications] = useState([]);

    const [openInfoModal, setOpenInfoModal] = useState(false);
    const [infoContent, setInfoContent] = useState({ name: '', description: '' });

    const [itemForm, setItemForm] = useState({
        name: null,
        description: null,
        unit_price: null,
        quantity: null,
        unit: null,
    });
    const [itemFormErrors, setItemFormErrors] = useState({
        name: '',
        description: '',
        unit_price: '',
        quantity: '',
        unit: ''
    });

    const handleOpenItemModal = () => {
        setItemForm((prevState) => ({
            ...prevState,
            building_id: id,
        }));
        setOpenItemModal(true);
    };

    const handleCloseItemModal = () => {
        setOpenItemModal(false);
        setItemForm({ name: null, description: null, quantity: null, unit: null, unit_price: null });
        setItemFormErrors({ name: '', description: '', quantity: '', unit: '', unit_price: '' });
    };

    const handleItemChange = (event) => {
        const { name, value } = event.target;
        setItemForm((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        setItemFormErrors((prevState) => ({
            ...prevState,
            [name]: ''
        }));
    };

    const handleItemSubmit = async () => {
        try {
            const errors = validateItemForm();
            if (Object.keys(errors).length > 0) {
                setItemFormErrors(errors);
                return;
            }
            setItemsError(null);
            setLoading(true);
            const result = await api().post(`/buildings/add_item`, itemForm);
            if (result.status < 300) {
                alert('Partida añadida correctamente');
                handleCloseItemModal();
                setItemForm((prevState) => ({
                    ...prevState,
                    name: null,
                    description: null,
                    quantity: null,
                    unit: null,
                    unit_price: null
                }));
                const building = result.data;
                setBudget(building.budget);
                setItems(building.items);

            } else {
                setItemsError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setItemsError(`Error: ${error.response?.data?.detail || error.message}`);
        } finally {
            setLoading(false);
        }
    };

    const validateItemForm = () => {
        const errors = {};
        if (!itemForm.name) {
            errors.name = 'El nombre es requerido';
        }
        if (!itemForm.unit_price) {
            errors.unit_price = 'El precio unitario es requerido';
        } else {
            const unit_price = parseFloat(itemForm.unit_price);
            if (unit_price > 99000000) {
                errors.unit_price = "El precio unitario no puede ser mayor a 99 millones.";
            } else if (!Number.isInteger(unit_price * 100)) {
                errors.unit_price = "El precio unitario solo puede tener hasta 2 decimales.";
            }
            else if (unit_price <= 0) {
                errors.unit_price = 'El precio unitario debe ser mayor que 0';
            }
        }
        if (!itemForm.quantity) {
            errors.quantity = 'La cantidad es requerida';
        } else {
            const quantity = parseFloat(itemForm.quantity);
            if (quantity > 99000000) {
                errors.quantity = "La cantidad no puede ser mayor a 99 millones.";
            } else if (!Number.isInteger(quantity * 100)) {
                errors.quantity = "La cantidad solo puede tener hasta 2 decimales.";
            }
            else if (itemForm.quantity <= 0) {
                errors.quantity = 'La cantidad debe ser mayor que 0';
            }
        }
        if (!itemForm.unit) {
            errors.unit = 'La unidad es requerida';
        }
        return errors;
    };

    const fetchBuilding = useCallback(async () => {
        try {
            setLoading(true);
            const result = await api().get(`/buildings/${id}`);
            const data = result.data
            const building = data.building
            setBuildingForm(building);
            setBuildingForm(prevState => ({ ...prevState, building_id: id }));
            setSelectedClient(building.client);
            setSelectedBoss(building.boss);
            setClient(building.client);
            setSelectedWorkers(building.workers);
            setStatus(building.building_status);
            setDeliveryNotes(building.delivery_notes);
            setCertifications(data.certifications);
            setItems(building.items);
            setBudget(building.budget);

            setClientInvoices(data.client_invoices);
            setClientTotalPriceSum(data.total_price_sum_client);
            setClientPaidAmountSum(data.paid_amount_sum_client);
            setClientRemainingAmountSum(data.remaining_amount_sum_client);
            setClientTotalRetention(data.total_retentions_client);
            setClientPaidRetention(data.paid_retention_client);
            setClientRemainingRetentionSum(data.remaining_retention_sum_client);

            setProviderInvoices(data.provider_invoices);
            setProviderTotalPriceSum(data.total_price_sum_provider);
            setProviderPaidAmountSum(data.paid_amount_sum_provider);
            setProviderRemainingAmountSum(data.remaining_amount_sum_provider);
            setProviderTotalRetention(data.total_retentions_provider);
            setProviderPaidRetention(data.paid_retention_provider);
            setProviderRemainingRetentionSum(data.remaining_retention_sum_provider);

            setLoading(false);
        } catch (error) {
            setEditError('Error obteniendo obra', error);
            setLoading(false);
        }
    }, [api, id]);


    const fetchClients = useCallback(async () => {
        try {
            setLoading(true);
            const result = await api().get('/clients/');
            setClients(result.data[0]);
            setLoading(false);
        } catch (error) {
            setEditError(`Error al obtener clientes: ${error.message}`);
        }
    }, [api]);

    const fetchWorkers = useCallback(async () => {
        try {
            const result = await api().get('/workers/');
            setWorkers(result.data[0]);
        } catch (error) {
            setWorkersError(`Error al obtener trabajadores: ${error.message}`);
        }
    }, [api]);

    useEffect(() => {
        fetchBuilding();
        fetchWorkers();
        fetchClients();
    }, []);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setBuildingForm((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleBossChange = (event, newValue) => {
        setSelectedBoss(newValue);
        setBuildingForm((prevState) => ({
            ...prevState,
            boss_id: newValue ? newValue.id : '',
        }));
    };

    const handleStatusChange = (event) => {
        const { value } = event.target;
        setStatus(value);
        setBuildingForm((prevState) => ({
            ...prevState,
            building_status: value,
        }));
    };
    const handleDateChange = (name, newValue) => {
        const date = dayjs(newValue).hour(12).minute(0).second(0).utc().format('YYYY-MM-DD');
        setBuildingForm((prevState) => ({
            ...prevState,
            [name]: date,
        }));
    };

    const handleClientChange = (event, newValue) => {
        setSelectedClient(newValue);
        setBuildingForm((prevState) => ({
            ...prevState,
            client_id: newValue ? newValue.id : '',
        }));
    };

    const handleAddWorker = async () => {
        if (!selectedWorkerId) return;

        try {
            setLoading(true);
            const result = await api().patch(`/buildings/add_worker`, { building_id: id, user_id: selectedWorkerId });
            if (result.status < 300) {
                alert('Trabajador añadido correctamente');
                setSelectedWorkerId(null);
                fetchBuilding();
                setWorkersError(null);
            } else {
                setWorkersError(`Error ${result.status}: ${result.data.detail}`);

            }
        } catch (error) {
            setWorkersError(`Error: ${error.response?.data?.detail || error.message}`);

        } finally {
            setLoading(false);
        }
    };

    const validateForm = () => {
        if (!buildingForm.name) {
            return 'Por favor, añada un nombre';
        }
        if (buildingForm.name.length > 36) {
            return 'El nombre no debe exceder los 36 caracteres';
        }
        if (!buildingForm.client_id) {
            return 'Por favor, seleccione un cliente';
        }
        if (!buildingForm.building_status) {
            return 'Por favor, seleccione el estado de la obra';
        }
        if (buildingForm.retention) {
            const integerRegex = /^\d+$/;
            if (!integerRegex.test(buildingForm.retention.toString()) || buildingForm.retention < 0 || buildingForm.retention > 100) {
                return 'La retención debe ser un número entero comprendido entre el 0 y el 100';
            }
        }
        return null;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setEditError(null);
        const validationError = validateForm();
        if (validationError) {
            setEditError(validationError);
            return;
        }
        try {
            setLoading(true);
            const result = await api().patch(`/buildings/update`, buildingForm);
            if (result.status < 300) {
                alert('Obra actualizada correctamente');
                const building = result.data;
                setBuildingForm(building);
                setBuildingForm(prevState => ({ ...prevState, building_id: id }));
                setSelectedClient(building.client);
                setClient(building.client);
                setStatus(building.building_status);
                setEditError(null);
            } else {
                setEditError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setEditError(`Error: ${error.response?.data?.detail || error.message}`);
        } finally {
            setLoading(false);
        }
    };

    const handleOpenConfirmModal = (worker) => {
        setWorkerToDissasociate(worker);
        setConfirmModalOpen(true);
    };

    const handleCloseConfirmModal = () => {
        setConfirmModalOpen(false);
        setWorkerToDissasociate(null);
    };

    const handleConfirmDissasociate = async () => {
        if (!workerToDissasociate) return;

        try {
            setLoading(true);
            const result = await api().patch(`/buildings/remove_worker`, { building_id: id, user_id: workerToDissasociate.id });
            if (result.status < 300) {
                alert('Trabajador desasociado correctamente');
                fetchBuilding();
                handleCloseConfirmModal();
                setWorkersError(null);
            } else {
                setWorkersError(`Error ${result.status}: ${result.data.detail}`);
            }
        } catch (error) {
            setWorkersError(`Error: ${error.response?.data?.detail || error.message}`);
        } finally {
            setLoading(false);
        }
    };

    const handleInfoOpen = (content) => {
        setInfoContent(content);
        setOpenInfoModal(true);
    };

    const handleInfoClose = () => {
        setOpenInfoModal(false);
        setInfoContent({ name: '', description: '' });
    };

    const handleDeleteItem = async () => {
        try {
            let data = {}
            if (typeDelete === '/buildings/disable') data.building_id = deleteId
            if (typeDelete === '/buildings/disable_item') data.item_id = deleteId
            if (typeDelete === '/certifications/disable') data.certification_id = deleteId
            if (typeDelete === '/invoices/disable') data.invoice_id = deleteId
            if (typeDelete === '/orders/disable_dn') data.delivery_note_id = deleteId

            const result = await api().patch(`${typeDelete}`, data);

            if (result.status < 300) {
                alert('Eliminado correctamente');
                if (typeDelete === '/buildings/disable') {
                    navigate(`/buildings/list`);
                } else if (typeDelete === '/buildings/disable_item') {
                    const building = result.data
                    setItems(building.items);
                    setBudget(building.budget);
                }
                fetchBuilding();
                setOpenDeleteDialog(false);
            } else {
                alert(`Error ${result.status}: ${result.data.detail}`)

            }
        } catch (error) {
            alert(`Error: ${error.response?.data?.detail || error.message}`)
        };
    }
    const handleOpenDeleteDialog = (itemId, type) => {
        setDeleteId(itemId);
        setTypeDelete(type);
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    const handleDownloadDocument = async (documentId, documentName) => {
        try {
            const response = await api().get(`/documents/${documentId}`, {
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', documentName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            alert(`Error al descargar el documento: ${JSON.stringify(error.response.data.detail)}`);
        }
    };


    return (
        <div style={{ padding: 20 }}>
            <Grid container spacing={3} style={{ position: 'fixed', top: 60, right: 10, zIndex: 1100, width: 'auto' }}>
                <Grid item>
                    <Paper elevation={0} style={{ padding: '10px', textAlign: 'center', backgroundColor: '#fff' }}>
                        <Box mb={2}>
                            <Typography variant="h5" component="h1" gutterBottom>
                                {buildingForm.code} - {buildingForm.name}
                            </Typography>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>

            <Grid item xs={12} md={10} lg={8}>
                <Accordion title={`${buildingForm.code} ${buildingForm.name}`}>
                    <form onSubmit={handleSubmit} style={{ padding: '10px' }}>
                        <Grid container spacing={2} style={{ padding: 8 }}>
                            {/* Primera fila: Nombre, Presupuesto, Cliente y Estado */}
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    label="Nombre"
                                    name="name"
                                    value={buildingForm.name || ''}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="normal"
                                    InputLabelProps={{ shrink: !!buildingForm.name }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Autocomplete
                                    options={clients}
                                    getOptionLabel={(option) => option.name || ''}
                                    value={selectedClient || null}
                                    onChange={handleClientChange}
                                    renderInput={(params) => <TextField {...params} label="Cliente" margin="normal" />}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <FormControl fullWidth margin="normal">
                                    <InputLabel id="status-label">Estado</InputLabel>
                                    <Select
                                        labelId="status-label"
                                        id="status"
                                        name="status"
                                        value={status}
                                        onChange={handleStatusChange}
                                        label="Estado"
                                    >
                                        <MenuItem value="not_started">NO EMPEZADA</MenuItem>
                                        <MenuItem value="in_course">EN CURSO</MenuItem>
                                        <MenuItem value="finished">TERMINADA</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <TextField
                                    fullWidth
                                    label="Presupuesto"
                                    name="budget"
                                    value={budget.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '€'}
                                    margin="normal"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Autocomplete
                                    options={workers}
                                    getOptionLabel={(option) => option.name?.toString()}
                                    value={selectedBoss}
                                    onChange={handleBossChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            margin="normal"
                                            label="Encargado"
                                            style={{ maxWidth: '100%' }}
                                        />
                                    )}
                                />
                            </Grid>
                            {/* Segunda fila: Fecha inicio y Fecha final */}
                            <Grid item xs={12} sm={3}>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                    adapterLocale="es"
                                    localeText={{
                                        cancelButtonLabel: "cancelar",
                                        okButtonLabel: "Ok",
                                        datePickerToolbarTitle: 'Seleccionar',
                                    }}>
                                    <MobileDatePicker
                                        label="Fecha inicio"
                                        closeOnSelect={true}
                                        value={buildingForm.start_date ? dayjs(buildingForm.start_date) : null}
                                        onChange={(newValue) => handleDateChange('start_date', newValue)}
                                        renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                    adapterLocale="es"
                                    localeText={{
                                        cancelButtonLabel: "cancelar",
                                        okButtonLabel: "Ok",
                                        datePickerToolbarTitle: 'Seleccionar',
                                    }}>
                                    <MobileDatePicker
                                        label="Fecha final"
                                        closeOnSelect={true}
                                        value={buildingForm.end_date ? dayjs(buildingForm.end_date) : null}
                                        onChange={(newValue) => handleDateChange('end_date', newValue)}
                                        renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                                    />
                                </LocalizationProvider>
                            </Grid>

                            {/* Tercera fila: Descripción */}
                            <Grid item xs={12}>
                                <TextField
                                    label="Descripción"
                                    name="description"
                                    value={buildingForm.description || ''}
                                    onChange={handleChange}
                                    multiline
                                    rows={4}
                                    fullWidth
                                    margin="normal"
                                    inputProps={{ maxLength: 255 }}
                                />
                            </Grid>

                            {/* Cuarta fila: Botones */}
                            <Grid item xs={6}>
                                <Button variant="contained" color="secondary" onClick={() => handleOpenDeleteDialog(id, '/buildings/disable')} fullWidth style={{ backgroundColor: 'red' }}>
                                    Eliminar
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button type="submit" variant="contained" color="primary" disabled={loading} fullWidth>
                                    Actualizar
                                </Button>
                            </Grid>

                            {/* Error */}
                            {editError && (
                                <Grid item xs={12}>
                                    <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                                        {editError}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>

                    </form>
                </Accordion>
                <Accordion title='Operarios'>
                    <Typography variant="h6" gutterBottom>
                        Operarios Asociados
                    </Typography>
                    <List>
                        {selectedWorkers.map((worker) => (
                            <ListItem key={worker.id}>
                                <ListItemText primary={`${worker.name}`} />
                                <ListItemSecondaryAction>
                                    <IconButton
                                        edge="end"
                                        aria-label="expulsar"
                                        onClick={() => handleOpenConfirmModal(worker)}
                                    >
                                        Desasociar
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                    <FormControl fullWidth style={{ marginTop: 20 }}>
                        <InputLabel id="add-worker-label">Añadir Trabajador</InputLabel>
                        <Select
                            labelId="add-worker-label"
                            id="add-worker"
                            value={selectedWorkerId}
                            onChange={(e) => setSelectedWorkerId(e.target.value)}
                            label="Añadir Trabajador"
                        >
                            {workers.map((worker) => (
                                <MenuItem key={worker.id} value={worker.id}>
                                    {worker.name}
                                </MenuItem>
                            ))}
                        </Select>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAddWorker}
                            disabled={!selectedWorkerId || loading}
                            style={{ marginTop: 10 }}
                        >
                            Añadir Trabajador
                        </Button>
                    </FormControl>
                    {workersError && (
                        <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                            {workersError}
                        </Typography>
                    )}
                </Accordion>
                <Accordion title='Partidas de la obra'>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                        <Button variant="contained" color="primary" onClick={handleOpenItemModal} sx={{ mb: 2 }}>
                            Añadir Partida
                        </Button>
                        <p style={{ marginBottom: 30 }}><strong>Presupuesto: </strong>{budget.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '€'}</p>
                    </div>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nombre</TableCell>
                                    <TableCell>Descripción</TableCell>
                                    <TableCell>Precio unitario</TableCell>
                                    <TableCell>Cantidad</TableCell>
                                    <TableCell>Importe total</TableCell>
                                    <TableCell>Acciones</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {items.filter(item => !item.disabled).map((item) => (
                                    <TableRow key={item.id}>
                                        <TableCell>{item.name}</TableCell>
                                        <TableCell>
                                            {item.description
                                                ? (item.description.length > 50
                                                    ? item.description.substring(0, 50) + '...'
                                                    : item.description)
                                                : ''}
                                        </TableCell>
                                        <TableCell>{item.unit_price ? item.unit_price.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '€' : null}</TableCell>
                                        <TableCell>{item.quantity ? item.quantity.toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) : null} {item.unit === 'l' ? 'LITROS' :
                                            item.unit === 'kg' ? 'KGS' :
                                                item.unit === 'uds' ? 'UNIDADES' :
                                                    item.unit}
                                        </TableCell>
                                        <TableCell>
                                            {item.unit_price && item.quantity
                                                ? (item.unit_price * item.quantity).toLocaleString('es-ES', { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '€'
                                                : null}
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                variant="outlined"
                                                onClick={() => handleInfoOpen({ name: item.name, description: item.description })}
                                            >
                                                Ver descripción
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => handleOpenDeleteDialog(item.id, '/buildings/disable_item')}
                                                sx={{ ml: 2 }}
                                            >
                                                Borrar
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {itemsError && (
                        <Typography variant="body2" color="error" style={{ marginTop: 10 }}>
                            {itemsError}
                        </Typography>
                    )}
                </Accordion>
                <Accordion title='Detalles del cliente'>
                    <Grid container spacing={3} style={{ marginBottom: 20 }}>
                        <Grid item xs={6}>
                            <Typography variant="subtitle1">Nombre:</Typography>
                            <Typography variant="body1">{client.name} {client.surname}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="subtitle1">Código cliente:</Typography>
                            <Typography variant="body1">
                                <Link to={`/clients/update/${client.id}`}>
                                    {client.code}
                                </Link>
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="subtitle1">Correo:</Typography>
                            <Typography variant="body1">{client.email}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="subtitle1">Teléfono:</Typography>
                            <Typography variant="body1">{client.telephone_number}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="subtitle1">CIF:</Typography>
                            <Typography variant="body1">{client.cif}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="subtitle1">Dirección:</Typography>
                            <Typography variant="body1">{client.country} {client.autonomus_community} {client.province} {client.cp} {client.address}</Typography>
                        </Grid>
                    </Grid>
                </Accordion>
                <CertificationsTable
                    showBuilding={false}
                    showCreateCertification={true}
                    buildingItems={items}
                    buildingId={id}
                    certifications={certifications}
                    fetch={fetchBuilding}
                    handleOpenDeleteDialog={handleOpenDeleteDialog}
                />
                <Accordion title='Albaranes'>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Código</TableCell>
                                    <TableCell>Fecha</TableCell>
                                    <TableCell>Acciones</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {deliveryNotes.filter(item => !item.disabled).map((item) => (
                                    <TableRow key={item.id}>
                                        <TableCell>
                                            <Link to={`/delivery_notes/update/${item.id}`}>
                                                {item.code}
                                            </Link>
                                        </TableCell>
                                        <TableCell>{formatDate(item.dn_date)}</TableCell>
                                        <TableCell>
                                            {item.documents && item.documents.length > 0 && (
                                                <Button
                                                    onClick={() =>
                                                        handleDownloadDocument(item.documents[0].id, item.documents[0].name)
                                                    }
                                                    sx={{ marginRight: 2 }}
                                                >
                                                    {item.documents[0].name}
                                                </Button>
                                            )}
                                            <UploadDocument
                                                to={'delivery_note_id'}
                                                id={item.id}
                                                fetch={fetchBuilding}
                                            >
                                            </UploadDocument>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={() => handleOpenDeleteDialog(item.id, '/orders/disable_dn')}
                                                sx={{ ml: 2 }}
                                            >
                                                Borrar
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Accordion>
                <InvoiceRetentionTable
                    isInvoice={true}
                    isClient={true}
                    showBuilding={false}
                    showClientOrProvider={true}
                    title='Facturas a clientes'
                    totalAmount={clientTotalPriceSum}
                    paidAmount={clientPaidAmountSum}
                    remainingAmountSum={clientRemainingAmountSum}
                    invoices={clientInvoices}
                    fetch={fetchBuilding}
                    handleOpenDeleteDialog={handleOpenDeleteDialog}
                />
                <InvoiceRetentionTable
                    isInvoice={false}
                    isClient={true}
                    showBuilding={false}
                    showClientOrProvider={true}
                    title='Retenciones a clientes'
                    totalAmount={clientTotalRetention}
                    paidAmount={clientPaidRetention}
                    remainingAmountSum={clientRemainingRetentionSum}
                    invoices={clientInvoices}
                    fetch={fetchBuilding}
                    handleOpenDeleteDialog={handleOpenDeleteDialog}
                />
                <InvoiceRetentionTable
                    isInvoice={true}
                    isClient={false}
                    uploadDocument={true}
                    showBuilding={false}
                    showClientOrProvider={true}
                    title='Facturas a proveedores'
                    totalAmount={providerTotalPriceSum}
                    paidAmount={providerPaidAmountSum}
                    remainingAmountSum={providerRemainingAmountSum}
                    invoices={providerInvoices}
                    fetch={fetchBuilding}
                    handleOpenDeleteDialog={handleOpenDeleteDialog}
                />
                <InvoiceRetentionTable
                    isInvoice={false}
                    uploadDocument={false}
                    isClient={false}
                    showBuilding={false}
                    showClientOrProvider={true}
                    title='Retenciones a proveedores'
                    totalAmount={providerTotalRetention}
                    paidAmount={providerPaidRetention}
                    remainingAmountSum={providerRemainingRetentionSum}
                    invoices={providerInvoices}
                    fetch={fetchBuilding}
                    handleOpenDeleteDialog={handleOpenDeleteDialog}
                />

                <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
                    <DialogTitle>¿Estás seguro?</DialogTitle>
                    <DialogActions>
                        <Button onClick={handleCloseDeleteDialog} color="primary">
                            Volver
                        </Button>
                        <Button onClick={handleDeleteItem} color="error">
                            Eliminar
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={openItemModal} onClose={handleCloseItemModal} maxWidth="sm" fullWidth>
                    <DialogTitle>Añadir Partida</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Por favor, complete el formulario para añadir una nueva partida.
                        </DialogContentText>
                        <Box sx={{ mt: 2, mb: 2 }}>
                            <TextField
                                fullWidth
                                label="Nombre"
                                name="name"
                                value={itemForm.name || ''}
                                onChange={handleItemChange}
                                error={!!itemFormErrors.name}
                                helperText={itemFormErrors.name}
                            />
                        </Box>
                        <Box sx={{ mt: 2, mb: 2 }}>
                            <TextField
                                fullWidth
                                multiline
                                rows={4}
                                label="Descripción"
                                name="description"
                                value={itemForm.description || ''}
                                onChange={handleItemChange}
                                error={!!itemFormErrors.description}
                                helperText={itemFormErrors.description}
                            />
                        </Box>
                        <Box sx={{ mt: 2, mb: 2 }}>
                            <TextField
                                fullWidth
                                label="Precio Unitario"
                                name="unit_price"
                                type="number"
                                value={itemForm.unit_price || ''}
                                onChange={handleItemChange}
                                error={!!itemFormErrors.unit_price}
                                helperText={itemFormErrors.unit_price}
                            />
                        </Box>
                        <Box sx={{ mt: 2, mb: 2 }}>
                            <TextField
                                fullWidth
                                label="Cantidad"
                                name="quantity"
                                type="number"
                                value={itemForm.quantity || ''}
                                onChange={handleItemChange}
                                error={!!itemFormErrors.quantity}
                                helperText={itemFormErrors.quantity}
                            />
                        </Box>
                        <Box sx={{ mt: 2, mb: 2 }}>
                            <FormControl fullWidth error={!!itemFormErrors.unit}>
                                <InputLabel id={`unit-label`}>Unidad</InputLabel>
                                <Select
                                    labelId={`unit-label`}
                                    name="unit"
                                    fullWidth
                                    value={itemForm.unit || ''}
                                    onChange={handleItemChange}
                                    label="Unidad"
                                >
                                    <MenuItem value="uds">UDS</MenuItem>
                                    <MenuItem value="kg">KG</MenuItem>
                                    <MenuItem value="l">L</MenuItem>
                                </Select>
                                {!!itemFormErrors.unit && (
                                    <FormHelperText>{itemFormErrors.unit}</FormHelperText>
                                )}
                            </FormControl>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseItemModal} color="secondary">
                            Cancelar
                        </Button>
                        <Button onClick={handleItemSubmit} disabled={!itemForm.unit || !itemForm.unit_price || !itemForm.name || !itemForm.quantity} color="primary">
                            Añadir Partida
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={openInfoModal} onClose={handleInfoClose}>
                    <DialogTitle>Información</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Nombre: {infoContent.name}</DialogContentText>
                        <DialogContentText>Descripción: {infoContent.description}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleInfoClose} color="primary">
                            Cerrar
                        </Button>
                    </DialogActions>
                </Dialog>

                <Modal
                    open={confirmModalOpen}
                    onClose={handleCloseConfirmModal}
                    closeAfterTransition
                >
                    <Fade in={confirmModalOpen}>
                        <div style={{ backgroundColor: '#fff', padding: 20, maxWidth: 400, margin: 'auto', marginTop: 200 }}>
                            <Typography variant="h6" gutterBottom>
                                Confirmar desasociación
                            </Typography>
                            <Typography variant="body1">
                                ¿Está seguro que desea desasociar al trabajador "{workerToDissasociate?.name}"?
                            </Typography>
                            <Grid container spacing={2} style={{ marginTop: 20 }}>
                                <Grid item xs={6}>
                                    <Button onClick={handleCloseConfirmModal} fullWidth>
                                        Cancelar
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleConfirmDissasociate}
                                        fullWidth
                                    >
                                        Confirmar
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </Fade>
                </Modal>
            </Grid>
        </div >
    );
};

export default EditBuilding;
